<script>
import InputField from '@/components/general/InputField'
import ListItem from '@/components/general/ListItem'
import EmptyList from '@/components/general/EmptyList'
import Loading from '@/components/general/Loading.vue'
export default {
  name: 'ActiveDepartments',
  components: {
    InputField,
    ListItem,
    EmptyList,
    Loading
  },
  data () {
    return {
      search: '',
      isReady: false,
      data: [],
      departments: [],
      edit: false
    }
  },
  created () {
    this.getDepartments()
  },
  methods: {
    getDepartments () {
      this.$store.dispatch('attemptGetDepartments')
        .then(({ data }) => {
          this.departments = data.departments.filter(d => d.active).map((department) => (
            {
              id: department._id,
              name: department.name
            }
          ))
          this.departments.sort((a, b) => a.name.localeCompare(b.name))
          this.isReady = true
          this.data = this.departments
        })
    },
    deleteDepartment (id) {
      this.$store.dispatch('attemptDeleteDepartments', id)
        .then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('settings.admincenter.departments.labels:remove.success')
          })
          this.getDepartments()
        })
        .catch(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('settings.admincenter.departments.labels:remove.error')
          })
        })
    }
  },
  watch: {
    search: function (value) {
      this.departments = this.data.filter(department => {
        return department.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      })
    },
    $route: {
      handler (to, from) {
        if (to.params.edit === true) {
          this.getDepartments()
        }
        this.edit = false
      }
    }
  }
}
</script>
<template>
  <div>
    <Loading v-if="!isReady" />
    <div class="active-departments--container" v-else>
      <div class="active-departments--filter-actions">
        <input-field class="mr-2" outlined dense :label="$t('global:search.alt')" v-model="search"
          append-icon="mdi-magnify"></input-field>
        <v-btn class="btn transform-unset" :color="getPrimaryColor" dark
          @click="$router.push({ name: 'business.admin.center.departments.new' })"><v-icon size="18px" class="mr-2"
            color="#fff">mdi-plus</v-icon>{{ $t('admin.center.departments.new') }}</v-btn>
      </div>
      <div class="active-departments--list">
        <list-item v-for="department in departments" :key="department.id" :text="department.name"
          :modalConfirmTitle="$t('modal.confirmation:departments.removal.title')"
          :modalConfirmDescription="$t('modal.confirmation:departments.removal.description', { name: department.name })"
          viewBtn deleteable @deleteItem="deleteDepartment" :item="department.id"
          @view="$router.push({ name: 'business.admin.center.departments.linked.positions', params: { id: department.id } })"></list-item>
        <empty-list v-if="departments.length == 0" :title="$t('empty.state.title:department.active.title')"
          :textOne="$t('empty.state.text:department.active.text')" :image="'/assets/images/empty-bush.png'"></empty-list>
      </div>
      <router-view></router-view>
    </div>
</div>
</template>
<style lang="scss">
.active-departments--container {
  .active-departments--filter-actions {
    display: flex;
    justify-content: flex-end;
  }
}

</style>
